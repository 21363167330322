<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td rowspan="2" colspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="3">
            沙特地区当地岗位服务外包人员单价信息<br />
            Saudi Manpower Recruitment Hourly
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center">SN</td>
          <td class="text-center">Category & Discipline</td>
          <td class="text-center">Job Profession</td>
          <td class="text-center">Qualification</td>
          <td class="text-center">Min Rate (SAR/Hr)</td>
          <td class="text-center">Max Rate (SAR/Hr)</td>
          <td class="text-center">Remark</td>
        </tr>
        <tr v-for="(item, index) in Details" :key="index">
          <td class="text-center">
            <div class="name no">{{ index + 1 }}</div>
          </td>
          <td class="text-left">
            <div class="name">
              <el-select
                v-model="item.Category"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in SpecialityNameList"
                  :key="item.ID"
                  :label="item.NameCHS"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </div>
          </td>
          <td class="text-left">
            <div class="name">
              <el-select
                v-model="item.JobProfession"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in positionList"
                  :key="item.ID"
                  :label="item.PositionsCHS"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </div>
          </td>
          <td class="text-left">
            <div class="name">
              <el-select
                v-model="item.QulificationID"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in opinions1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </td>
          <td class="text-left">
            <div class="name">
              <el-input v-model="item.MinimumRate" />
            </div>
          </td>
          <td class="text-left">
            <div class="name">
              <el-input v-model="item.MaximumRate" />
            </div>
          </td>
          <td class="text-left">
            <div class="name">
              <el-input v-model="item.Remark" />
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center">Note</td>
          <td class="text-left" colspan="6">
            Food Accomdation and Transportation Provided by Local Supplier.
          </td>
        </tr>

        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Approval Record<br />
            审批记录
          </td>

          <td class="text-center" colspan="2">
            Approved by SNEMC HR Department 中东公司人力资源部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC Authorized 中东公司授权人
          </td>
        </tr>
        <tr>
          <td class="text-title" colspan="2">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCHRDepartment" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  addManpowerRecruitmentHourlyRate,
  editManpowerRecruitmentHourlyRate,
  getDepartmentAll,
  allPositionCategory,
  allPosition,
} from "@/api/user";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      ID: null,
      KeyID: null,
      newDate: null,
      Remark:
        "Food Accomdation and Transportation Provided by Manpower Supplier.",
      EditState: 1,
      Details: [],
      DetailItem: {
        ID: null,
        KeyID: null,
        ManpowerRecruitmentHourlyRateKeyID: null,
        ProjectID: null,
        CompanyID: null,
        Category: null,
        CategoryName: null,
        JobProfession: null,
        JobProfessionName: null,
        QulificationID: null,
        QulificationName: null,
        MinimumRate: null,
        MaximumRate: null,
        Remark: null,
      },
      departmentList: [],
      positionList: [],
      SpecialityNameList: [],
      ApprovedbySNEMCHRDepartment: null,
      ApprovedbySNEMCAuthorized: null,
      ApplicationNumber: null,
      TableDate: null,
      opinions1: [
        {
          value: 1,
          label: "Aramco",
        },
        {
          value: 2,
          label: "General",
        },
        {
          value: 3,
          label: "Sabic ",
        },
      ],
    };
  },
  methods: {
    getNewData(data1, data2) {
      this.departmentList = data1;
      this.positionList = data2;
      this.TableDate = getNewDate();
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.newDate = null;

      this.ApprovedbySNEMCHRDepartment = null;
      this.ApprovedbySNEMCAuthorized = null;
      this.Remark =
        "Food Accomdation and Transportation Provided by Local Supplier. ";
      this.EditState;
      this.Details = [];
      for (let i = 0; i < 10; i++) {
        const item = JSON.parse(JSON.stringify(this.DetailItem));
        this.Details.push(item);
      }
      this.Details = this.Details.concat();
    },
    addManpowerRecruitmentHourlyRate(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        Date: this.newDate,
        Remark: this.Remark,
        EditState: EditState,
        Details: [],
      };
      this.Details.forEach((item) => {
        if (item.Category) {
          data.Details.push(item);
        }
      });
      if (!data.ID) {
        addManpowerRecruitmentHourlyRate(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      } else {
        editManpowerRecruitmentHourlyRate(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      }
    },

    getEditData(data1, data2, data) {
      this.departmentList = data1;
      this.positionList = data2;
      this.ID = data.ID;
      this.KeyID = data.KeyID;
      this.newDate = data.Date;
      this.Remark = data.Remark;
      this.TableDate = data.Date	;
      this.ApplicationNumber = data.ApplicationNumber;
      this.ApprovedbySNEMCHRDepartment = data.ApprovedbySNEMCHRDepartment;
      this.ApprovedbySNEMCAuthorized = data.ApprovedbySNEMCAuthorized;

      if (data.Details) {
        if (data.Details.length <= 10) {
          data.Details.forEach((item, index) => {
            item.CategoryID = item.Category;
            item.QulificationID = item.Qulification;
            this.Details[index] = item;
          });
        } else {
          data.Details.forEach((item, index, array) => {
            item.CategoryID = item.Category;
            item.QulificationID = item.Qulification;
            array[index] = item;
          });
          this.Details = data.Details;
        }
      }
      this.Details = this.Details.concat();
    },
  },
  created() {
    for (let i = 0; i < 10; i++) {
      const item = JSON.parse(JSON.stringify(this.DetailItem));
      this.Details.push(item);
    }
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });

    allPositionCategory().then((res) => {
      if (res.status === 200) {
        this.SpecialityNameList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.teding {
  max-width: 200px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}
.customize-main {
  width: 100%;
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
    font-weight: bolder;
    margin-right: 100px;
  }
  .customize-footer {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    width: 100%;
    .text-box {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      div {
        margin-bottom: 8px;
      }
      .remark {
        width: 100px;
        flex-shrink: 0;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 5px;
      padding-right: 5px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      margin: 10px 0;
      color: #000;
      // font-weight: bolder;
      padding: 5px;
      .tbale-img1 {
        width: 100px;
      }
      h1 {
        font-size: 20px;
        color: #000;
        margin: 10px 0;
      }
      p {
        font-size: 16px;
      }
      .name {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
      .no {
        font-weight: normal;
      }
      .name-size {
        font-size: 16px;
      }
      .name-wide {
        font-weight: bolder;
      }
      .nameEn {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
      color: #000;
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-left {
      color: #000;
      font-size: 13px;
      .flex {
        display: flex;
        font-size: 16px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;
        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .el-select {
        width: 100%;
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        div {
          width: 50%;
        }
      }
      .name {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
      }
      .nameEn {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
      }
      .el-checkbox {
        margin-right: 0;
      }
    }
    .text-size {
      font-size: 16px;
      color: #000;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
      }
      div {
        margin: 10px 0;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
      .textarea {
        min-width: 65vw;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
  .signature-box {
    display: flex;
    .left-box {
      margin-left: 100px;
      width: 500px;
      margin-top: 50px;
      display: flex;
      align-items: center;
      .left {
        width: 250px;
        text-align: center;
        font-size: 16px;
        color: #000;
        line-height: 28px;
      }
      .img-box {
        max-width: 100px;
        img {
          width: 100%;
        }
      }
    }
    .right-box {
      margin-left: 100px;
      width: 500px;
      margin-top: 50px;
      display: flex;
      align-items: center;
      .right {
        width: 250px;
        text-align: center;
        font-size: 16px;
        color: #000;
        line-height: 28px;
      }
      .img-box {
        max-width: 100px;
        img {
          width: 100%;
        }
      }
    }
  }
  .signature-bottom {
    margin-bottom: 40px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box {
  display: flex;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.is-disabled >>> .el-input__inner {
  background-color: #fff;
  color: #000;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>