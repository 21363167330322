<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">外包人员单价</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >人事管理</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >外包管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>外包人员单价</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">备注:</div>
          <el-input v-model="remark" placeholder="请输入备注"></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">申请日期:</div>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getManpowerRecruitmentHourlyRate(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
        <button class="btn-pre" @click="drawLine">
          <i class="fa ion-ios-pulse"></i>统计
        </button>

        <button class="btn-pre" @click="uploadAllInfo({})">
          <i class="fa fa-upload"></i>导入
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="ID" label="ID" width="80" />
          <el-table-column prop="KeyID" label="KeyID" width="100" />
          <el-table-column prop="Date" label="制表日期" width="80">
          </el-table-column>
          <el-table-column prop="EditState" label="编辑状态" width="80">
            <template slot-scope="scope">
              <div
                v-if="scope.row.EditState === 1"
                class="label-warning approval"
              >
                未发布
              </div>
              <div
                v-if="scope.row.EditState === 2"
                class="label-primary approval"
              >
                已发布
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AuditStatus" label="审批状态" width="130">
            <template slot-scope="scope">
              <div
                v-if="scope.row.AuditStatus === null"
                class="label-primary approval"
              >
                待发布
              </div>
              <div
                v-if="scope.row.AuditStatus === 0"
                class="label-primary approval"
              >
                待审核
              </div>
              <div
                v-if="scope.row.AuditStatus === 1"
                class="label-success approval"
              >
                审核通过
              </div>
              <div
                v-if="scope.row.AuditStatus === 2"
                class="label-info approval"
              >
                审核中
              </div>
              <div
                v-if="scope.row.AuditStatus === 3"
                class="label-warning approval"
              >
                审核未通过
              </div>
              <div
                v-if="scope.row.AuditStatus === 4"
                class="label-danger approval"
              >
                驳回
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Remark" label="备注" width="150" />
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <i class="fa fa-search" @click="query(scope.row)"></i>
              <i
                class="fa fa-edit"
                @click="edit(scope.row)"
                v-if="powers.update"
              ></i>
              <i
                class="fa fa-trash"
                @click="delelteManpowerRecruitmentHourlyRate(scope.row)"
                v-if="powers.delete"
              ></i>
              <i
                class="fa fa-image"
                @click="image(scope.row)"
                v-if="powers.upload"
              ></i>
              <i
                class="fa fa-print"
                v-if="powers.export || powers.print"
                @click="goUrl(scope.row)"
              ></i>
              <i
                class="fa fa-upload"
                @click="uploadAllInfo(scope.row)"
                v-if="powers.upload"
              ></i>
              <i
                class="fa fa-download"
                @click="downloadAll(scope.row)"
                v-if="powers.download"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getManpowerRecruitmentHourlyRate(1)"
          @current-change="getManpowerRecruitmentHourlyRate"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="外包人员单价"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <UnitPrice
          ref="unitPrice"
          @updata="getManpowerRecruitmentHourlyRate"
          @close="handleClose"
        ></UnitPrice>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button
          class="pop-save"
          :disabled="disabled || EditState == 2"
          @click="save"
          >保存</el-button
        >
        <el-button
          class="pop-release"
          :disabled="disabled || EditState == 2"
          @click="release"
          >发布</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="附件上传"
      :visible.sync="dialogVisible2"
      width="600px"
      :before-close="handleClose2"
      top="50px"
      class="dialog"
    >
      <el-table
        :data="tableData2"
        border
        class="tableData2"
        tooltip-effect="light"
        empty-text="暂无附件"
        :header-cell-style="{
          color: '#333',
          background: '#f9f9f9',
          'font-weight': 'bolder',
        }"
      >
        <el-table-column type="index" label="序号" width="40" />
        <el-table-column prop="Name" label="文件名称" width="150" />
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <i
              class="fa fa-cloud-download"
              @click="download(scope.row.Url)"
            ></i>
            <i class="fa fa-trash" @click="deleteTrash(scope)"></i>
          </template>
        </el-table-column>
      </el-table>

      <div class="upload-box">
        <div class="left">
          <div v-if="name" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment"
          ref="uploadRef"
        >
          <button
            :disabled="tableData2.length >= 5"
            :class="['btn-up', { disabled: tableData2.length >= 5 }]"
          >
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose2">关闭</el-button>
        <el-button class="pop-save pop-right" @click="addAttachment"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      :title="`${KeyID ? '导入外包人员单价' : '创建外包人员单价'}`"
      :visible.sync="dialogVisible3"
      width="600px"
      :before-close="handleClose3"
      top="50px"
      class="dialog"
    >
      <div class="text-box text-box2" v-if="!KeyID">
        <div class="text-title">申请日期</div>
        <div class="text-name2">{{ createDate }}</div>
      </div>
      <div class="text-box">
        <div class="text-title">模板文件</div>
        <div class="text-name" @click="getDownloadUrl">
          外包人员单价批量导入模板
        </div>
      </div>
      <div class="upload-box">
        <div class="left">
          <div v-if="name2" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name2 }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment2"
          ref="uploadRef"
        >
          <button class="btn-up">
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose3">关闭</el-button>
        <el-button
          class="pop-save pop-right"
          v-if="KeyID"
          @click="addAttachment2"
          >导入</el-button
        >
        <el-button class="pop-save" v-if="!KeyID" @click="addAttachment2(1)"
          >保存</el-button
        >
        <el-button
          class="pop-release pop-right"
          v-if="!KeyID"
          @click="addAttachment2(2)"
          >发布</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="外包人员单价统计表"
      :visible.sync="dialogVisible4"
      width="1000px"
      :before-close="handleClose4"
      top="50px"
      class="dialog"
    >
      <div class="charts-box">
        <el-select
          v-model="departmentId"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.ID"
            :label="item.DepartmentCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="positionId"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in positionList"
            :key="item.ID"
            :label="item.PositionsCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="daterange2"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>

        <button class="btn-pre" @click="getManpowerRecruitmentHourlyRateStats">
          <i class="fa fa-search"></i>查询
        </button>
      </div>
      <el-empty description="暂无数据" v-if="dateList.length === 0"></el-empty>
      <div
        id="charts"
        v-show="dateList.length !== 0"
        style="width: 970px; height: 520px; background: #fff"
      ></div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import * as echarts from "echarts";
import {
  getManpowerRecruitmentHourlyRate,
  getDepartmentAll,
  allPosition,
  getManpowerRecruitmentHourlyRateItem,
  delelteManpowerRecruitmentHourlyRate,
  allAttachment,
  deleteAttachment,
  uploadAttachment,
  addAttachment,
  getManpowerRecruitmentHourlyRateTemplate,
  getManpowerRecruitmentHourlyRateImport,
  getManpowerRecruitmentHourlyRateExport,
  getManpowerRecruitmentHourlyRateStats,
  addManpowerRecruitmentHourlyRate2,
} from "@/api/user";
import UnitPrice from "@/components/tables/unitPrice.vue";
import { getNewDate } from "@/utils/date";
export default {
  data() {
    return {
      tableData: [],
      tableData2: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑
      options1: null,
      options2: [
        {
          value: 0,
          label: "待发布",
        },
        {
          value: 1,
          label: "审批通过",
        },
        {
          value: 2,
          label: "审批拒绝",
        },
        {
          value: 3,
          label: "审批中",
        },
        {
          value: 4,
          label: "待审批",
        },
      ],
      senior: false,
      remark: null,
      daterange: null,
      radio: "1",
      statesRadio: "",
      textarea: "",
      roleName: "",
      enable: false,
      page: 1,
      size: 10,
      total: 10,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      departmentList: [],
      positionList: [],
      disabled: false,
      EditState: 1,
      dialogVisible2: false,
      name: null,
      dialogVisible3: false,
      downloadUrl: null,
      uploadUrl: null,
      name2: null,
      dialogVisible4: false,
      charts: "",
      opinionData1: [], // 数据
      opinionData2: [],
      dateList: [],
      departmentId: null,
      positionId: null,
      daterange2: null,
      loading: true,

      KeyID: null,
      createDate: null,
    };
  },
  components: { UnitPrice },
  methods: {
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.disabled = false;
      this.EditState = 1;
      this.$refs.unitPrice.clearData();
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.name = null;
    },
    handleClose3() {
      this.dialogVisible3 = false;
      this.name2 = null;
    },
    handleClose4() {
      this.dialogVisible4 = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    newBuilt() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.unitPrice.getNewData(this.departmentList, this.positionList);
      }, 1);
    },
    query(data) {
      this.dialogVisible = true;
      this.disabled = true;
      this.getManpowerRecruitmentHourlyRateItem(data.KeyID);
    },
    edit(data) {
      this.dialogVisible = true;
      this.EditState = data.EditState;
      this.getManpowerRecruitmentHourlyRateItem(data.KeyID);
    },
    goUrl(data) {
      localStorage.setItem(
        "departmentList",
        JSON.stringify(this.departmentList)
      );
      localStorage.setItem("positionList", JSON.stringify(this.positionList));
      this.$router.push(
        `/print?type=3&print=${this.powers.print}&export=${this.powers.export}&key=${data.KeyID}`
      );
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //列表
    getManpowerRecruitmentHourlyRate(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      let beginDate = null;
      let endDate = null;
      if (this.daterange) {
        beginDate = this.daterange[0];
        endDate = this.daterange[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        remark: this.remark,
        beginDate: beginDate,
        endDate: endDate,
      };
      getManpowerRecruitmentHourlyRate(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //保存
    save() {
      this.$refs.unitPrice.addManpowerRecruitmentHourlyRate(1);
    },
    //发布
    release() {
      this.$refs.unitPrice.addManpowerRecruitmentHourlyRate(2);
    },
    //详情
    getManpowerRecruitmentHourlyRateItem(key) {
      getManpowerRecruitmentHourlyRateItem({ key }).then((res) => {
        if (res.status === 200) {
          this.$refs.unitPrice.getEditData(
            this.departmentList,
            this.positionList,
            res.response
          );
        }
      });
    },
    //删除
    delelteManpowerRecruitmentHourlyRate(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delelteManpowerRecruitmentHourlyRate({ key: data.KeyID }).then(
          (res) => {
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.getManpowerRecruitmentHourlyRate();
            }
          }
        );
      });
    },
    allAttachment() {
      allAttachment({
        workTable: "ManpowerRecruitmentHourlyRate",
        workTableKey: this.KeyID,
      }).then((res) => {
        if (res.status === 200) {
          this.tableData2 = res.response ? res.response : [];
        }
      });
    },
    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx的文件!"
        );
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          let FileType = 1;
          if (suffix || suffix2 || suffix3) {
            FileType = 2;
          }
          const item = {
            Name: data.name,
            Url: res.response,
            FileType,
          };
          this.name = data.name;
          this.tableData2.push(item);
        }
      });
    },
    image(data) {
      this.dialogVisible2 = true;
      this.KeyID = data.KeyID;
      this.FormID = data.ID;
      this.allAttachment();
    },
    deleteTrash(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (data.row.ID) {
          deleteAttachment({ id: data.row.ID }).then((res) => {
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.tableData2.splice(data.$index, 1);
            }
          });
        } else {
          this.$message.success("删除成功");
          this.tableData2.splice(data.$index, 1);
        }
      });
    },
    addAttachment() {
      const data = {
        WorkTable: "ManpowerRecruitmentHourlyRate",
        WorkTableKey: this.KeyID,
        FormID: this.FormID,
        ModuleID: 334,
        Details: [],
      };

      this.tableData2.forEach((item, index) => {
        const detailItem = {
          ID: 0,
          Name: item.Name,
          Url: item.Url,
          FileType: item.FileType,
          SortID: index + 1,
        };
        if (item.ID) {
          detailItem.ID = item.ID;
        }
        data.Details.push(detailItem);
      });
      addAttachment(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("保存成功");
          this.handleClose2();
        }
      });
    },
    //下载附件
    download(data) {
      location.href = process.env.VUE_APP_SERVER + "upload/" + data;
    },
    uploadAllInfo(data) {
      this.KeyID = data.KeyID;
      getManpowerRecruitmentHourlyRateTemplate().then((res) => {
        if (res.status === 200) {
          this.dialogVisible3 = true;
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          this.downloadUrl = "https://hrms.baishulai.com/" + url;
        }
      });
    },

    getDownloadUrl() {
      location.href = this.downloadUrl;
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
        }
      });
    },
    addAttachment2(type) {
      console.log("111", this.KeyID);
      if (this.KeyID) {
        const data = {
          Path: this.uploadUrl,
          KeyID: this.KeyID,
        };
        getManpowerRecruitmentHourlyRateImport(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("导入成功");
            this.handleClose3();
          }
        });
      } else {
        const data = {
          ID: null,
          KeyID: null,
          Date: null,
          Remark:
            "Food Accomdation and Transportation Provided by Local Supplier.",
          EditState: type,
          Path: this.uploadUrl,
        };
        addManpowerRecruitmentHourlyRate2(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.getManpowerRecruitmentHourlyRate();
            this.handleClose3();
          }
        });
      }
    },
    downloadAll(data) {
      getManpowerRecruitmentHourlyRateExport({ key: data.KeyID }).then(
        (res) => {
          if (res.status === 200) {
            let url = res.response;
            let charIndex = url.indexOf("Download");
            url = url.substr(charIndex, url.length);
            url = url.replace(/\\/g, "/");
            location.href = "https://hrms.baishulai.com/" + url;
          }
        }
      );
    },
    drawLine() {
      this.dialogVisible4 = true;
      this.$nextTick(() => {
        // 初始化折线图
        this.charts = echarts.init(document.getElementById("charts"));
        //  this.setOption()
      });
    },
    setOption() {
      this.charts.clear();
      this.charts.setOption({
        title: {
          left: "3%",
          top: "5%",
          text: "外包人员单价统计表", // 自定义
        },

        tooltip: {
          trigger: "axis",
        },

        legend: {
          align: "right",
          left: "3%",
          top: "15%",
          data: [], // 自定义
        },

        grid: {
          top: "30%",
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },

        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            show: true, // 为true 滚动条出现
            realtime: true,
            type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
            height: 10, // 表示滚动条的高度，也就是粗细
            start: 0, // 表示默认展示0%～80%这一段。
            end: 80,
            backgroundColor: "transparent",
            bottom: 10,
            textStyle: {
              color: "transparent",
            },
          },
        ],

        // 自定义：设置x轴刻度
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
          },
          // 自定义标签
          data: this.dateList,
        },

        // 自定义：设置y轴刻度
        yAxis: {
          type: "value",
          boundaryGap: true,
          splitNumber: 4,
          interval: 250,
        },

        // 设置数据
        series: [
          {
            name: "近一周", // 自定义
            type: "line",
            stack: "总量", // 自定义
            data: this.opinionData1, // 自定义
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgb(255,200,213)",
                  },
                  {
                    offset: 1,
                    color: "#ffffff",
                  },
                ],
                global: false,
              },
            },
            itemStyle: {
              color: "rgb(255,96,64)",
              lineStyle: {
                color: "rgb(255,96,64)",
              },
            },
          },
          {
            name: "近一周", // 自定义
            type: "line",
            stack: "总量", // 自定义
            data: this.opinionData2, // 自定义
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgb(255,200,213)",
                  },
                  {
                    offset: 1,
                    color: "#ffffff",
                  },
                ],
                global: false,
              },
            },
            itemStyle: {
              color: "rgb(255,96,64)",
              lineStyle: {
                color: "rgb(255,96,64)",
              },
            },
          },
        ],
      });
    },
    getManpowerRecruitmentHourlyRateStats() {
      let startDate = null;
      let endDate = null;
      if (this.daterange2) {
        startDate = this.daterange2[0];
        endDate = this.daterange2[1];
      }
      const data = {
        departmentId: this.departmentId,
        positionId: this.positionId,
        startDate: startDate,
        endDate: endDate,
      };
      getManpowerRecruitmentHourlyRateStats(data).then((res) => {
        if (res.status === 200) {
          if (res.response) {
            this.dateList = [];
            res.response.Dates.forEach((item) => {
              this.dateList.push(item.slice(0, 10));
            });

            this.opinionData1 = res.response.MaximumRates;
            this.opinionData2 = res.response.MinimumRates;
            // 设置折线图数据和样式
            this.setOption();
          }
        }
      });
    },
  },

  created() {
    this.getButtonPower();
    this.getManpowerRecruitmentHourlyRate();
    this.createDate = getNewDate();
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });

    // for (let i = 0; i < 100; i++) {
    //   this.dateList.push(`2024-01-${i}`);
    //   this.opinionData1.push(i);
    //   this.opinionData2.push(i + 10);
    // }
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    //height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      //height: calc(100% - 143px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.text-box2 {
  margin-bottom: 12px;
  align-items: center;
  .text-name2 {
    color: #000;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.charts-box {
  .el-select {
    margin-right: 12px;
  }
  .el-date-editor {
    margin-right: 12px;
  }
  .btn-pre {
    padding: 9px 12px;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
</style>
